import React, { useMemo, useState } from "react";
import { NextPage } from "next";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useUserContext } from "../contexts/UserContext";
import { withApollo } from "../components/WithApollo";
import useFormHook from "../hooks/useForm";
import { useRouter } from "next/router";
import gql from "graphql-tag";
import { Button } from "@/components/tailwind-ui/Button";
import { Input } from "@/components/tailwind-ui/Input";

const inputColors = {
  invalid: "border-red-400 placeholder-red-500",
  default: "border-gray-300 placeholder-gray-500",
};

const LOGIN = gql`
  mutation MyMutation($email: String!) {
    login(email: $email) {
      __typename
      success
    }
  }
`;

const OrderRepairs: NextPage = (props: any) => {
  const { theme } = props;
  const router = useRouter();
  const { userData } = useUserContext();
  if (userData) {
    router.push("/");
    return null;
  }

  const [login, loginResponse] = useMutation(LOGIN);
  const [success, setSuccess] = useState(false);
  const showResult = loginResponse.called && !loginResponse.loading;
  const form = useFormHook();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const variables = Object.fromEntries(new FormData(e.currentTarget));
    const { email } = form.formValues;
    const res = await login({ variables });
    setSuccess(res.data.login.success);
  };
  if (showResult && success) {
    return (
      <div className="flex flex-col items-center justify-center min-h-full my-auto">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
            Eine E-Mail mit dem Link zum Einloggen wurde an ihre E-Mail Addresse geschickt.
            <br />
            Bitte prüfen Sie ihr Postfach.
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col items-center justify-center min-h-full my-auto">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            {theme === "colosseum" && (
              <div className="text-right text-gray-700">
                <img src="/Colosseum Logo.svg" alt="Colosseum Dental Logo" className="w-auto h-12 mx-auto" />
                <span>
                  powered by <span className="font-semibold">Desos</span>
                </span>
              </div>
            )}
            {theme === "winkelstueck-reparatur" && (
              <img src="/Winkelstück Reparatur.jpg" alt="Winkelstück Reparatur Logo" className="w-auto h-12 mx-auto" />
            )}
            {theme === "desos" && <img src="/desos/Logo_türkis.svg" alt="Desos Logo" className="w-auto h-12 mx-auto" />}
          </div>
          <form className="mt-8 space-y-6" onSubmit={onSubmit}>
            <Input
              label="E-Mail zum Einloggen eingeben"
              required
              name="email"
              type="email"
              autoComplete="email"
              placeholder="E-Mail"
            />
            <Button type="submit" scheme="primary" size="2" className="flex justify-center w-full">
              Einloggen
            </Button>
            {showResult && !success && (
              <p className="mt-2 text-sm text-red-600">Kein Nutzer mit dieser E-Mail gefunden</p>
            )}
          </form>

          <div className="mt-8">
            <div className="relative">
              <div className="relative flex justify-center text-sm">
                <a href="/register" className="px-2 text-gray-500 underline bg-white">
                  Oder neu registrieren
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withApollo()(OrderRepairs);
